import { useState, useContext, useEffect } from 'react';
import { AuthenticationContext, AuthenticationContextProps, AuthenticationKeyValue, IPermissionDictionary } from 'components/Authentication';
import apiClient, { ApiClient } from 'api/api';
import { PermissionsRepsonse } from './utils/interfaces';
import { getUrlComponents } from 'utils/util';

let client: ApiClient;

function verifyAndSetupApiClient(baseUrl: string | null, authenticationContext: AuthenticationContextProps) {
	if (!client) {
		if (!baseUrl && !authenticationContext.endpoint) {
			throw new Error('Please provide a base URL');
		}

		const {baseURL, path: pathPrefix} = getUrlComponents(baseUrl ?? authenticationContext.endpoint);

		client = apiClient(baseURL, pathPrefix);
	}
}

function usePermissions(permissionRefNames: string[], baseUrl: string | null) {
	const [hasPermission, setHasPermission] = useState({} as IPermissionDictionary);
	const authenticationContext = useContext(AuthenticationContext);
	const [isLoading, setIsLoading] = useState(true);

	verifyAndSetupApiClient(baseUrl, authenticationContext);

	const hasPermissionFunc = async (permissionRefNames: string[]): Promise<IPermissionDictionary> => {
		if (!permissionRefNames || permissionRefNames.length == 0) return {};

		const permissionDictionary = {} as IPermissionDictionary;
		const storedConfigs = authenticationContext.permissionList;
		// check if all permission refs need to get are in memory cache
		if (storedConfigs && storedConfigs.length > 0) {
			const permissions = storedConfigs.filter((storedConfig) => permissionRefNames.includes(storedConfig.key));
			if(permissions.length == permissionRefNames.length) {
				permissions.forEach((item) =>  permissionDictionary[item.key] = item.value);
				return permissionDictionary;
			}
		}

		try {
			const params = new URLSearchParams();
			permissionRefNames.forEach(refName => params.append("permissionRefNames", refName));
			const responseData = await client.get<PermissionsRepsonse>('list?' + params.toString());
			if(!responseData.isSuccess) {
				return {};
			}

			Object.keys(responseData.value).forEach((key) => {
				const keyValue: AuthenticationKeyValue = {
					key: key,
					value: responseData.value[key],
				};

				if(!storedConfigs.find((item) => key == item.key)){
					storedConfigs.push(keyValue);
				}

				permissionDictionary[key] = keyValue.value;
			});
		} catch (error) {
			console.error(error);
		}

		return permissionDictionary;
	};

	useEffect(() => {
		hasPermissionFunc(permissionRefNames).then((value) => {
			setHasPermission(value);
			setIsLoading(false);
		});
	  }, []);

	return { isLoading, hasPermission };
}

export default usePermissions;
