import usePermissions from 'components/useAuthentication/usePermissions';

const withPermissions = (Component: React.ElementType, permissionRefNames: string[], baseUrl: string | null) => {
	const Wrapper = (props: object) => {
		const { isLoading, hasPermission } = usePermissions(permissionRefNames, baseUrl);

		return  <Component isPermissionLoading={isLoading} hasPermission={hasPermission} {...props} />;
	};

	return Wrapper;
};

export default withPermissions;
