import React, { ReactNode, useState, useMemo } from 'react';

export interface AuthenticationKeyValue {
	key: string;
	value: boolean;
}

export interface IPermissionDictionary {
	[index: string]: boolean;
}

export interface AuthenticationContextProps {
	permissionList: AuthenticationKeyValue[];
	setPermissionList: React.Dispatch<React.SetStateAction<AuthenticationKeyValue[]>>;
	endpoint: string;
}

interface AuthenticationProviderProps {
	children: ReactNode;
	endpoint: string;
}

export const AuthenticationContext = React.createContext<AuthenticationContextProps>({
	permissionList: [],
	setPermissionList: () => [],
	endpoint: '',
});

const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({ children, endpoint }) => {
	const [permissionList, setPermissionList] = useState([] as AuthenticationKeyValue[]);

	const contextValue = useMemo(() => {
		return { permissionList, setPermissionList, endpoint };
	}, [permissionList, setPermissionList, endpoint]);

	return <AuthenticationContext.Provider value={contextValue}>{children}</AuthenticationContext.Provider>;
};

export default AuthenticationProvider;