import { FC, ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { AlertStates } from 'components/Alert';
import Toast from 'components/Toast';
import { generateUUID } from 'utils/util';

type ShowToastOptions = {
	message: string;
	state: keyof typeof AlertStates;
	timeOut?: number;
	header?: string;
	classNames?: string;
};

interface IToastContext {
	showToast: (options: ShowToastOptions) => void;
}

const ToastContext = createContext<IToastContext>({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
	showToast: (options: ShowToastOptions) => {},
});

export const useToastContext = () => {
	return useContext(ToastContext);
};

interface ToastProviderProps {
	children: ReactNode;
}

export const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
	const [toast, setToast] = useState<ReactNode>(null);

	const showToast = useMemo(
		() => (options: ShowToastOptions) => {
			setToast(<Toast key={generateUUID()} {...options} />);
		},
		[toast]
	);

	return (
		<ToastContext.Provider value={{ showToast }}>
			<div style={{ position: 'relative' }}>
				<div
					style={{
						position: 'absolute',
						width: '100%',
						left: 0,
						pointerEvents: 'none',
					}}
				>
					{toast}
				</div>
			</div>
			{children}
		</ToastContext.Provider>
	);
};
