import { useState, useContext, useEffect } from 'react';
import { AuthenticationContext, AuthenticationContextProps, AuthenticationKeyValue } from 'components/Authentication';
import apiClient, { ApiClient } from 'api/api';
import { PermissionRepsonse } from './utils/interfaces';
import { getUrlComponents } from 'utils/util';

let client: ApiClient;

function verifyAndSetupApiClient(baseUrl: string | null, authenticationContext: AuthenticationContextProps) {
	if (!client) {
		if (!baseUrl && !authenticationContext.endpoint) {
			throw new Error('Please provide a base URL');
		}

		const {baseURL, path: pathPrefix} = getUrlComponents(baseUrl ?? authenticationContext.endpoint);

		client = apiClient(baseURL, pathPrefix);
	}
}

function usePermission(permissionRefName: string, baseUrl: string | null) {
	const [authenticationValues, setAuthenticationValues] = useState([] as AuthenticationKeyValue[]);
	const authenticationContext = useContext(AuthenticationContext);
	const [hasPermission, setHasPermission] = useState(Boolean);
	const [isLoading, setIsLoading] = useState(true);

	verifyAndSetupApiClient(baseUrl, authenticationContext);

	const hasPermissionFunc = async (permissionRefName: string): Promise<boolean> => {
		if (!permissionRefName) return false;

		const storedConfigs = authenticationContext.permissionList;
		// check config in context first
		if (storedConfigs && storedConfigs.length > 0) {
			const permission = storedConfigs.find((storedConfig) => permissionRefName == storedConfig.key);
			if(permission) {
				return permission.value;
			}
		}

		try {
			const responseData = await client.get<PermissionRepsonse>(permissionRefName);

			const keyValue: AuthenticationKeyValue = {
				key: permissionRefName,
				value: responseData.isSuccess && responseData.value,
			};

			storedConfigs.push(keyValue);

			setAuthenticationValues(storedConfigs);
			authenticationContext.setPermissionList(storedConfigs);

			return keyValue.value;
		} catch (error) {
			console.error(error);
		}
		return false;
	};

	useEffect(() => {
		hasPermissionFunc(permissionRefName).then((value) => {
			setHasPermission(value);
			setIsLoading(false);
		});
	  }, []);

	return { isLoading, hasPermission };
}

export default usePermission;
